import React, { Fragment } from 'react'
import { Appointments } from '../../types'
import styled, { css, DefaultTheme } from '@xstyled/styled-components'

import { Wrapper } from './styled'
import { SEO } from '../../components/SEO'

import { Heading } from '../../components/Text'
import { TextBlock } from '../../components/ContentBlock/TextBlock'
import { useMedia } from '../../hooks'
import { Locations } from './Locations'
import { ImageTextBlock } from '../../components/ContentBlock/ImageTextBlock'
import { RichText } from '../../components/RichText'
interface AppointmentsProps {
  appointments: Appointments
}

const StoreImage = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    justify-content: center;
    margin: 0 auto;
    width: 100%;

    padding: 0 15%;

    ${theme.mediaQueries.tablet} {
      padding: 0;
    }
  `}
`
const StoreDescription = styled.div`
  ${({ theme }) => css`
    position: relative;
    margin: 0 auto;
    width: 100%;
    padding: 4 15% 0;

    p {
      margin: 4 0;
    }

    ${theme.mediaQueries.mobile} {
      padding-bottom: 12;
      h3 {
        font-size: ${theme.mobileFontSizes[3]};
      }
    }

    ${theme.mediaQueries.tablet} {
      padding: 0 0 6;

      h3 {
        font-size: ${theme.tabletFontSizes[3]};
      }
    }
  `}
`

const Grid = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    gap: 0;
    grid-template-columns: 1fr 1fr;
    max-width: 1600px;
    margin: 6 auto;
    ${theme.mediaQueries.tablet} {
      display: block;
    }
  `}
`

const Separator = styled.div`
  ${({ theme }) => css`
    width: 1px;
    height: 100%;
    background-color: body.4;

    ${theme.mediaQueries.tablet} {
      display: none;
    }
  `}
`

const StoreGrid = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: grid;
    gap: 0;

    grid-template-columns: 1fr 0fr 1fr;
    max-width: 1600px;
    margin: 6 auto;

    > div {
      &:nth-child(1) {
        order: 1;
      }
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(3) {
        order: 4;
      }
      &:nth-child(4) {
        order: 3;
      }
      &:nth-child(5) {
        order: 5;
        background-color: transparent;
      }
      &:nth-child(6) {
        order: 6;
      }
    }

    ${theme.mediaQueries.tablet} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 4;
      padding: 0 6;

      > div {
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 2;
        }
        &:nth-child(3) {
          order: 3;
        }
        &:nth-child(4) {
          order: 4;
        }
        &:nth-child(5) {
          order: 5;
        }
        &:nth-child(6) {
          order: 6;
        }
      }
    }
  `}
`

const PageTitleWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    padding: 9 0;
    h1 {
      max-width: none;
      margin: 0 auto;
      line-height: 1em;
    }
      ${theme.mediaQueries.tablet} {
          padding: 6 0;

       h1 {
      max-width: 25rem;
      }
  `}
`

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 5;
  h1 {
    max-width: 26rem;
    margin: 0 auto;
    line-height: 1em;
  }
`

const PopupsHeadingWrapper = styled.div`
  ${({ theme }) => css`
    margin: 8 8;
    padding-bottom: 5;

    h1 {
      max-width: 26rem;
      margin: 0 auto;
      line-height: 1em;
    }

    ${theme.mediaQueries.mobile} {
      max-width: 16rem;
      margin: 0 auto;
    }
  `}
`
const PopupsWrapper = styled.div`
  ${({ theme }) => css`
    margin: 4 0;
    > div > div {
      h4 {
        padding-bottom: 5;
      }
      h3 {
        padding-bottom: 1;
      }
      h5 {
        padding-bottom: 5;
      }

      min-height: unset;
    }
    ${theme.mediaQueries.tablet} {
      > div > div {
        margin: 6 0;
      }
    }
  `}
`

const DescriptionWrapper = styled.div`
  ${({ theme }) => css`
    h4 {
      padding-bottom: 3;
    }
    > div > div {
      min-height: unset;
    }
    ${theme.mediaQueries.tablet} {
      > div > div {
        margin: 6 0;
      }
    }
  `}
`
export const AppointmentsView = ({ appointments }: AppointmentsProps) => {
  const {
    seo,
    title,
    _id,
    description,
    appointmentLocations,
    upcomingPopups,
    storeLocations,
    appointmentSectionTitle,
  } = appointments

  console.log('storeLocations', storeLocations)
  const defaultSeo = {
    title: title || 'Stores & Appointments',
    description: seo?.description,
    image: seo?.image,
  }

  const isMedium = useMedia({
    maxWidth: '1000px',
  })

  return (
    <>
      <SEO
        seo={seo}
        defaultSeo={defaultSeo}
        path="about/stores"
        contentType={_id!}
      />
      <Wrapper tabIndex={-1}>
        <PageTitleWrapper>
          <Heading level={1} textAlign="center">
            {title || 'Stores & Appointments'}
          </Heading>
        </PageTitleWrapper>
        <StoreGrid>
          {storeLocations?.map((storeLocation, index) => (
            <Fragment key={index}>
              <StoreImage>
                {storeLocation?.image && (
                  <ImageTextBlock content={storeLocation.image} />
                )}
              </StoreImage>
              <Separator />
              <StoreDescription>
                {storeLocation?.bodyRaw && (
                  <RichText body={storeLocation.bodyRaw} />
                )}
              </StoreDescription>
            </Fragment>
          ))}
        </StoreGrid>

        <Grid>
          <TitleWrapper>
            <Heading level={1} textAlign="center">
              {appointmentSectionTitle || 'Book an Appointment'}
            </Heading>
          </TitleWrapper>

          {description && (
            <DescriptionWrapper>
              <TextBlock content={description} />
            </DescriptionWrapper>
          )}
          <Locations isMedium={isMedium} locations={appointmentLocations} />
          <PopupsHeadingWrapper>
            <Heading level={1} textAlign="center">
              {upcomingPopups?.title || 'Upcoming'}
            </Heading>
          </PopupsHeadingWrapper>
          <PopupsWrapper>
            {upcomingPopups?.description && (
              <TextBlock content={upcomingPopups?.description} />
            )}
          </PopupsWrapper>
        </Grid>
      </Wrapper>
    </>
  )
}
